import { differenceInDays } from 'date-fns'

export const applyMaxInterval = (
  dateSince: Date | null,
  dateTill: Date | null,
  maxInterval: number,
): [Date | null, Date | null] => {
  if (dateSince && dateTill) {
    const diff = differenceInDays(dateTill, dateSince)
    if (diff > maxInterval) {
      dateSince = new Date(dateTill.getTime() - maxInterval * 24 * 60 * 60 * 1000)
    }
  }
  return [dateSince, dateTill]
}

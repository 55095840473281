import { CountryEnum } from 'shared/enums/country-enum'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { getLanguageCodeByLocale } from '../enums/language-code-enum'

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export interface CountryDataInterface {
  id: CountryEnum
  caption: string
}

const getCountryName = (key: CountryEnum, locale: LocaleEnum) => {
  const localeKey = getLanguageCodeByLocale(locale)
  switch (key) {
    // Related SC https://app.shortcut.com/systeme/story/15639/change-the-name-of-taiwan-province-of-china-to-taiwan
    case CountryEnum.Taiwan:
      return countries.getName(key, localeKey, { select: 'alias' })
    default:
      return countries.getName(key, localeKey, { select: 'official' })
  }
}

export const getCountryData = (locale: LocaleEnum = LocaleEnum.ENGLISH): CountryDataInterface[] => {
  countries.registerLocale(
    require(`i18n-iso-countries/langs/${getLanguageCodeByLocale(locale)}.json`),
  )
  return Object.values(CountryEnum).map(el => ({
    id: el,
    caption: getCountryName(el, locale),
  }))
}

export const getSingleCountry = (
  key: CountryEnum,
  locale: LocaleEnum = LocaleEnum.ENGLISH,
): string => {
  countries.registerLocale(
    require(`i18n-iso-countries/langs/${getLanguageCodeByLocale(locale)}.json`),
  )
  return getCountryName(key, locale)
}
